//Funcion para listar/eliminar markers en localStorage
export const addMarker = (id) =>{
  if(localStorage.getItem("markerview")){
      const storage = localStorage.getItem("markerview" )
        var array_storage = storage.split("-")
        array_storage = array_storage.filter(element => element != "")
        if(array_storage.find(element => element === id.toString())){
            // localStorage.setItem("markerview","")
            // var array_new = (array_storage.filter(element => element != id.toString()))
            // for (let index = 0; index < array_new.length; index++) {
            //     localStorage.setItem("markerview", (localStorage.getItem("markerview")  + array_new[index] + "-"))
            // }
            // return null
        }
        else{
            localStorage.setItem("markerview", (localStorage.getItem("markerview") +  id + "-"))
        }
  }
  else{
      localStorage.setItem("markerview", id + "-")
  }
    return null
}

//Función para checkear un marker
export const checkMarker = (id) => {
  var storage = localStorage.getItem("markerview") // Recupero los ids
  if(!storage){localStorage.setItem("markerview","")} // Si no hay nada en el localStorage lo inicializo
  var storage = localStorage.getItem("markerview").split("-") // Recupero los ids
  storage = storage.filter(element => element !== "") // Elimino si hay algun espacio vacio
  if(storage.find(element => element.toString() === id.toString())){
    return true;
  }
  return false
}

//Funcion para traer todos los markers
export const getMarkers = () => {
  var storage = localStorage.getItem("markerview") // Recupero los ids
  if(!storage){localStorage.setItem("markerview","")} // Si no hay nada en el localStorage lo inicializo
  storage = localStorage.getItem("markerview").split("-") // Recupero los ids
  storage = storage.filter(element => element !== "") // Elimino si hay algun espacio vacio
  return storage //Retorno array de ids
}

var panPath = [];   // An array of points the current panning action will use
var panQueue = [];  // An array of subsequent panTo actions to take
var STEPS = 300;     // The number of steps that each panTo action will undergo

//Function to center map smoothly and zoom in

export const handlerClick = (newLat, newLng, mapRef) => {
  if (panPath.length > 0) {
    panQueue.push([newLat, newLng]);
    return;
  }
  // Lets compute the points we'll use
  panPath.push("LAZY SYNCRONIZED LOCK");  // make length non-zero - 'release' this before calling setTimeout
  const curLat = mapRef.current.getCenter().lat();
  const curLng = mapRef.current.getCenter().lng();
  const dLat = (newLat - curLat)/STEPS;
  const dLng = (newLng - curLng)/STEPS;

  for (var i=0; i < STEPS; i++) {
    panPath.push([curLat + dLat * i, curLng + dLng * i]);
  }
  panPath.push([newLat, newLng]);
  panPath.shift();      // LAZY SYNCRONIZED LOCK
  setTimeout(doPan(mapRef), 20);
}

export const doPan = (mapRef) => {
  const next = panPath.shift();
  if (next != null) {
    // Continue our current pan action
    mapRef?.current?.panTo( new window.google.maps.LatLng(next[0], next[1]));
    setTimeout(doPan(mapRef), 20 );
  } else {
    // We are finished with this pan - check if there are any queue'd up locations to pan to 
    const queued = panQueue.shift();
    if (queued != null) {
      panTo(queued[0], queued[1]);
    }
  }
}

export const deleteSharp = (draw,dispatch,action) => {
  draw.setMap(null)
  dispatch(action(null))
}